import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { ThemeProvider } from 'styled-components'

import { Pagination, Table } from 'ui/components'
import { getDailyAnalytics } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { formatDate } from 'utils/masks'

import { renderItemValue } from '../commonFuncs'
import Container from './styles'

export default function ({ _location }) {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [analyticsList, setAnalyticsList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(false)

  const analyticsListDaily = async (options = {}) => {
    let res = null
    try {
      res = await getDailyAnalytics(
        apiKey,
        userId,
        panelEnv,
        {
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar analytics')
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setAnalyticsList(res.data)
    setLoading(false)
  }

  useEffect(() => {
    analyticsListDaily()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    setLoading(true)
    analyticsListDaily(options)
  }

  const renderAnalyticsItems = (list) => {
    return _.map(list, (item, i) => {
      const analytics = item[`${panelEnv}_data`]
      const { total } = analytics

      return (
        <tr key={i.toString()}>
          <td>{formatDate(item.initial_date, { hideTime: true })}</td>
          <td>{total}</td>
          {/* <td>{renderItemValue(analytics.total_processed, total)}</td> */}
          {/* <td>{renderItemValue(analytics.total_processing, total)}</td> */}
          <td>{renderItemValue(analytics.total_approved, total)}</td>
          <td>{renderItemValue(analytics.total_reproved, total)}</td>
          <td>{renderItemValue(analytics.total_in_blacklist, total)}</td>
          {/* BV onepld: nao exibir <td>{renderItemValue(analytics.total_has_pep, total)}</td> */}
          <td>{renderItemValue(analytics.total_has_processes_unclassified, total)}</td>
          <td>{renderItemValue(analytics.total_has_processes_whitelist, total)}</td>
          <td>{renderItemValue(analytics.total_has_contingency, total)}</td>
        </tr>
      )
    })
  }

  return (
    <ThemeProvider theme={{}}>
      <Container>
        <h1 className="title-container">Consultas {panelEnv.toUpperCase()}</h1>

        <Table className="table">
          <thead>
            <tr>
              <th>Período</th>
              <th>Consultas</th>
              {/* <th>Processados</th> */}
              {/* <th>Processando</th> */}
              <th>Aprovados</th>
              <th>Reprovados</th>
              <th>Bloqueados</th>
              {/* BV onepld: nao exibir <th>PEPs</th> */}
              <th>Processos sem classificação</th>
              <th>Processos na Whitelist</th>
              <th>Com contingência</th>
            </tr>
          </thead>

          <tbody>
            {loading || renderAnalyticsItems(analyticsList)}
          </tbody>
        </Table>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />
      </Container>
    </ThemeProvider>
  )
}
