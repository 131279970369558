import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { ThemeProvider } from 'styled-components'
import { CircularProgress, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import RemoveIcon from '@material-ui/icons/Remove'
import { ConsultStatusIcon } from 'utils/icons'

import {
  ActionButtons,
  ButtonDownloadReportPDF,
  DateFilter,
  Pagination,
  SearchDoc,
  Table,
  TitleView,
} from 'ui/components'
import { ModalContainerPanels } from 'ui/components/ContainerCommonPanels/utilsFunctions'

import { getList } from 'services/apiMotorBgc'
import { formatDate, formatDocNumber } from 'utils/masks'
import { PANEL_ENVS } from 'utils/constants/environments'
import reportError from 'utils/errorReporter'

import SwitchPdfType from './SwitchPdfType'
import Container from './styles'

export default function ({
  actionsAnalysisStatus,
  analysisStatus,
  hasPep,
  hasProcessesUnclassified,
  hasProcessesWhitelist,
  hasContingency,
  hasContingencyRevision,
  manualDerivationStatus,
  manualDerivationToRole,
  color,
  location,
  slowProcessing,
  isTakingTimeToProcess,
  inexistent,
  status,
  title,
  batchId,
  subtitle,
  withManualAnalysis,
  itemWithManualAnalysis,
}) {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  let servicesList = null
  switch (panelEnv) {
    case PANEL_ENVS.CPF:
      servicesList = useSelector((state) => state.planCpfServices.services)
      break
    case PANEL_ENVS.CNPJ:
      servicesList = useSelector((state) => state.planCnpjServices.services)
      break
    default:
  }

  const urlParams = new URLSearchParams(location.search)
  const queryParamDocNumber = urlParams.get(panelEnv)
  // TODO: implementar os defaults de data
  // const queryParamInitialDate = urlParams.get('initial_date')
  // const queryParamFinalDate = urlParams.get('final_date')

  const savedCallbackListDocs = useRef()

  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [filterDoc, setFilterDoc] = useState(queryParamDocNumber || '')

  const [consultsList, setConsultsList] = useState([])
  const [totalRequests, setTotalRequests] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemSelected, setItemSelected] = useState({})
  const [loading, setLoading] = React.useState(true)
  const [pdfReportOnlyRequestedServices, setPdfReportOnlyRequestedServices] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const theme = {
    color: color,
  }

  const listDocs = async (options = {}) => {
    let res = null

    try {
      res = await getList(
        apiKey,
        userId,
        panelEnv,
        {
          analysisStatus: analysisStatus,
          hasPep: hasPep,
          hasProcessesUnclassified: hasProcessesUnclassified,
          hasProcessesWhitelist: hasProcessesWhitelist,
          hasContingency: hasContingency,
          hasContingencyRevision: hasContingencyRevision,
          manualDerivationStatus: manualDerivationStatus,
          manualDerivationToRole: manualDerivationToRole,
          status: status,
          initialDate: _.has(options, 'initialDate') ? options.initialDate : initialDate,
          finalDate: _.has(options, 'finalDate') ? options.finalDate : finalDate,
          docNumber: _.has(options, panelEnv) ? options[panelEnv] : filterDoc,
          page: _.has(options, 'page') ? options.page : currentPage,
          slowProcessing: slowProcessing,
          isTakingTimeToProcess: isTakingTimeToProcess,
          inexistent: inexistent,
          batchId: batchId,
          withServices: true,
          withManualAnalysis: withManualAnalysis,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalRequests(xTotal)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setConsultsList(res.data[`${panelEnv}_datas`])
  }

  const callbackListDocs = () => listDocs()

  useEffect(() => {
    savedCallbackListDocs.current = callbackListDocs
  })

  useEffect(() => {
    listDocs()

    const callListDocs = () => savedCallbackListDocs.current()
    const intervalId = setInterval(callListDocs, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (_.isEmpty(servicesList)) return

    setLoading(false)
  }, [servicesList, consultsList])

  const closeModalAndRefreshList = () => {
    setItemSelected({})
    setModalOpen(false)
    setLoading(true)
    listDocs()
  }

  const handleToggleModal = (item) => {
    setItemSelected(item)
    setModalOpen(!modalOpen)
  }

  const handleFilter = async (initial, final) => {
    const options = {
      initialDate: initial,
      finalDate: final,
      page: 1,
    }

    setLoading(true)
    setInitialDate(initial)
    setFinalDate(final)
    listDocs(options)
  }

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    setLoading(true)
    listDocs(options)
  }

  const handleDocFIlter = async (docNumber) => {
    const options = {
      docNumber: docNumber,
      page: 1,
    }

    setLoading(true)
    setFilterDoc(docNumber)
    listDocs(options)
  }

  const handleRemoveItemFromList = (docId) => {
    const newConsultsList = _.reject(consultsList, { doc_id: docId })
    setConsultsList(newConsultsList)

    toast.success('Atualização feita com sucesso.')
  }

  const handlePdfReportType = (selectedOption) => {
    setPdfReportOnlyRequestedServices(!selectedOption)
  }

  // eslint-disable-next-line no-unused-vars
  const renderActionButtons = (doc) => {
    return (
      <ActionButtons
        doc={doc}
        actionsAnalysisStatus={actionsAnalysisStatus}
        onSuccess={() => handleRemoveItemFromList(doc.doc_id)}
      />
    )
  }

  const renderService = (serviceId, doc) => {
    const serviceInfo = _.find(doc.service_infos, { service_id: serviceId })

    return <td key={serviceId}><ConsultStatusIcon consult={serviceInfo} /></td>
  }

  const renderFromQsa = (doc) => {
    if (_.isNil(doc.cnpj_member)) return <RemoveIcon style={{ color: '#ddd' }} />

    return <CheckIcon style={{ color: '#ddd' }} />
  }

  const renderDocList = (list) => {
    return _.map(list, doc => (
      <tr key={doc.doc_id} onClick={() => handleToggleModal(doc)} className="handler">
        <td className="sticky-col first-col">
          <div style={{ display: 'none' }}> { /* TODO: BV: disabled temporary report pdf */ }
          <ButtonDownloadReportPDF
            docId={doc.doc_id}
            value={doc[panelEnv]}
            createdAt={doc.created_at}
            withOnlyRequestedServices={pdfReportOnlyRequestedServices}
          />
          </div>
          {' '}
          {formatDocNumber(doc[panelEnv], panelEnv)}
        </td>
        <td style={{ fontSize: '1rem' }}>{doc.name}</td>
        <td style={{ fontSize: '1rem' }}>{formatDate(doc.created_at)}</td>
        <td style={{ fontSize: '1rem' }}>{renderFromQsa(doc)}</td>
        <td><ConsultStatusIcon consult={doc} /></td>

        <td className="cell-separator" />

        {servicesList.map(service => renderService(service.service_id, doc))}

        {/* BV: nao exibir
        <td className="sticky-col last-col">
          <div className="buttons-container">
            {renderActionButtons(doc)}
          </div>
        </td>
        */}
      </tr>
    ))
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <TitleView
          type="Consultas"
          title={title}
          subtitle={subtitle}
        />

        <div className="main">
          <div className="first-line">
            <div className="requests-container">
              <span className="counter">{`Quantidade: ${totalRequests}`}</span>
            </div>

            <div className="filters-container">
              <DateFilter
                onHandleFilter={(initial, final) => handleFilter(initial, final)}
              />

              <SearchDoc
                type={panelEnv}
                text={filterDoc}
                onSearch={(term) => handleDocFIlter(term)}
                onChangeText={(text) => setFilterDoc(text)}
              />
            </div>
          </div>

          <div className="view">
            <div style={{ display: 'none' }}> { /* TODO: BV: disabled temporary */ }
            <SwitchPdfType onChange={handlePdfReportType} />
            </div>

            {loading
            ? <div className="loader-container"><CircularProgress /></div>
            : (
              <React.Fragment>
                <div className="wrapper">
                  <Table className="table">
                    <thead>
                      <tr>
                        <th className="sticky-col sticky-th first-col first-th">Documento</th>
                        <th className="sticky-th">Nome</th>
                        <th className="sticky-th">Data</th>
                        <th className="sticky-th">De QSA?</th>
                        <th className="sticky-th">Status</th>

                        <th className="cell-separator">-</th>

                        {_.map(servicesList, item => (
                          <Tooltip
                            key={item.service_id}
                            placement="top"
                            title={<strong>{_.toUpper(item.name)}</strong>}
                          >
                            <th className="middle-col sticky-th">{item.name_short}</th>
                          </Tooltip>
                        ))}
                        {/* BV: <th className="sticky-col last-col sticky-th">Ações</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        consultsList.length === 0
                        ? (<tr><td colSpan={3}>Nenhum registro encontrado.</td></tr>)
                        : renderDocList(consultsList)
                      }
                    </tbody>
                  </Table>
                </div>

                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handleChangePage}
                />
              </React.Fragment>
            )}
          </div>

          <ModalContainerPanels
            docData={itemSelected}
            docType={panelEnv}
            onClose={handleToggleModal}
            open={modalOpen}
            withManualAnalysis={itemWithManualAnalysis}
            manualDerivationToRole={manualDerivationToRole}
            manualDerivationOnConfirm={closeModalAndRefreshList}
          />
        </div>
      </Container>
    </ThemeProvider>
  )
}
