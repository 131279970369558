import React from 'react'
import { Route, Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { useSelector, useDispatch } from 'react-redux'

import { Button, ButtonReportExport, GeneralSearch } from 'ui/components'
import { logout } from 'redux/auth/actions'

import { translateRole } from 'utils/constants/user'
import { PANEL_ENVS } from 'utils/constants/environments'

import ConfigNav from './ConfigNav'
import AnalyticsNav from './AnalyticsNav'
import ConsultNav from './ConsultNav'

import MenuMore from './menuMore'

import ManualDerivationNav from './ManualDerivationNav'

import { Menu, Container } from './styles'

const getOppositePanel = (panelEnv) => {
  if (panelEnv === PANEL_ENVS.CPF)
    return PANEL_ENVS.CNPJ
  return PANEL_ENVS.CPF
}

export default function ({ component: Component, ...rest }) {
  const dispatch = useDispatch()
  const currentPath = rest.location.pathname

  const userName = useSelector((state) => state.auth.name)
  // const userPermission = useSelector((state) => state.user.permission)
  const userRole = useSelector((state) => state.user.role)

  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const transition = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const handleClickMenuLogout = () => {
    dispatch(logout())
  }

  const handleClickMenuConfig = () => {
    window.location.pathname = '/config/user'
  }

  // eslint-disable-next-line no-unused-vars
  const handleChangePanel = () => {
    window.location.pathname = `/${getOppositePanel(panelEnv)}/processing`
  }

  const renderNav = () => {
    if (currentPath.startsWith(`/${panelEnv}/analytics`)) {
      return <AnalyticsNav currentPath={currentPath} />
    }

    if (currentPath.startsWith(`/config`)) {
      return <ConfigNav currentPath={currentPath} />
    }

    if (currentPath.startsWith(`/admin`)) {
      return <ConfigNav currentPath={currentPath} />
    }

    if (currentPath.startsWith(`/master`)) {
      return <ConfigNav currentPath={currentPath} />
    }

    if (currentPath.startsWith(`/${panelEnv}/manual-analysis`)) {
      return <ManualDerivationNav currentPath={currentPath} />
    }

    return <ConsultNav currentPath={currentPath} />
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <animated.div style={transition}>
          <Container>
            <Menu background="/assets/images/background/background_sidebar.png">
              <img
                className="logo"
                src="/assets/images/logos/logo_symbol.svg"
                alt="Logo"
              />

              <p className="version-text">
                {`version: ${process.env.REACT_APP_PROJECT_VERSION}`}
              </p>

              {renderNav(currentPath)}
            </Menu>

            <main>
              <div className="main-nav">
                <div className="nav-screens">
                  <div className="nav-screen-link">
                    <Link to={`/${panelEnv}/processing`}>Consultas</Link>
                  </div>

                  <div className="nav-screen-link">
                    <Link to={`/${panelEnv}/manual-analysis/in-queue-analyst`} className="nav-screen-link">Analises Manuais</Link>
                  </div>

                  <div className="nav-screen-link">
                    <Link to={`/${panelEnv}/analytics-consults`} className="nav-screen-link">Analytics</Link>
                  </div>
                </div>

                <Button variant="text" onClick={handleChangePanel}>
                  {`Ir para o Painel ${getOppositePanel(panelEnv)}`}
                </Button>

                <ButtonReportExport />

                <GeneralSearch placeholder={`Pesquisa geral por ${panelEnv.toUpperCase()}`} className="general-search" />

                <div>
                  <p>{userName}</p>
                  <p className="user-role">{translateRole(userRole)}</p>
                  {/* BV: nao exibir, pois ja exibe a role
                  <p className="user-permission">{translatePermission(userPermission)}</p>
                  */}
                </div>

                <MenuMore
                  onClickConfig={handleClickMenuConfig}
                  onClickLogout={handleClickMenuLogout}
                />
              </div>

              <div className="main-component">
                <Component {...matchProps} />
              </div>
            </main>
          </Container>
        </animated.div>
      )}
    />
  )
}
